<template>
  <apexchart
    type="donut"
    height="230"
    :options="chartOptions"
    :series="series"
  ></apexchart>
</template>

<script>
export default {
  props: {
    cdata: { type: Array },
  },
  data: () => ({
    series: [], //[100, 25],
    chartOptions: {
      chart: {
        width: 380,
        type: "donut",
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true,
              },
            },
          },
        },
      },
      fill: {
        type: "gradient",
      },
      colors: ["#2E7D32", "#FBC02D"],
      legend: {
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex];
        },
      },
      labels: [], //["Submitted", "Balance"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  }),
  mounted() {
    this.cdata.forEach((el) => {
      this.series.push(el.value);
      this.chartOptions.labels.push(el.status);
    });
  },
};
</script>

<style>
</style>